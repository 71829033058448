import React from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import Layout from "../components/layout"
import { Container, Subheading, Heading } from "../components/styled/Styled"
import Carousel from "../components/Carousel"
import { MEDIA_QUERIES, COLORS, formatDate } from "../constants"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const Section = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`

const Article = styled(motion.article)`
  background-color: ${COLORS.GREY};
  padding: 2rem;

  font-size: 1.2rem;

  a {
    color: ${COLORS.SECONDARY};
  }

  img {
    max-width: 600px;
  }

  blockquote {
    background: ${COLORS.BLACK};
    border-left: 10px solid ${COLORS.SECONDARY};
    margin: 1.5em 10px;
    padding: 0.5em 10px;

    &:before {
      color: ${COLORS.SECONDARY};
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }
    p {
      display: inline;
    }
  }
`

const ImageContainer = styled(motion.div)`
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    height: 300px;
  }
`

const Img = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
  }
`

const Date = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  color: ${COLORS.PRIMARY};
  width: 50%;
  font-size: 0.8rem;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 32.666%;
  }
  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    width: 100%;
    bottom: auto;
    top: 0;
  }

  p {
    background-color: ${COLORS.GREY};
    padding: 0.5rem;
    border-bottom-right-radius: 5px;
  }
`

const ProductionTemplate = ({
  data: {
    markdownRemark: { html, frontmatter: production },
  },
}) => {
  const start = formatDate(production.start)
  const end = formatDate(production.end)
  return (
    <Layout>
      <Container>
        <Heading
          initial={{ opacity: 0, x: "-500px" }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.75, ease: "easeOut" },
          }}
        >
          {production.title}
        </Heading>
        <Section>
          <ImageContainer
            initial={{ opacity: 0, y: 500 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.5, duration: 0.75, ease: "easeOut" },
            }}
          >
            <Img fluid={production.bannerimage.childImageSharp.fluid} />
            {production.start && (
              <Date>
                <p style={{ marginRight: "auto" }}>
                  {start} {production.end && production.start && `- ${end}`}
                </p>
              </Date>
            )}
          </ImageContainer>
          <Article
            initial={{ opacity: 0, x: "500px" }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { delay: 1.25, duration: 0.75, ease: "easeOut" },
            }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <Subheading
            style={{
              textTransform: "uppercase",
              textAlign: "center",
              fontSize: "3rem",
              marginTop: "4rem",
            }}
          >
            Gallery
          </Subheading>
          {production.gallery && <Carousel images={production.gallery} />}
        </Section>
      </Container>
    </Layout>
  )
}

export default ProductionTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        start
        end
        gallery {
          childImageSharp {
            fixed(quality: 90, height: 500) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        bannerimage {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
