import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Carousel } from "react-responsive-carousel"
import { MEDIA_QUERIES } from "../constants"

const CarouselContainer = styled(Carousel)`
  padding: 0 10%;
  margin: auto;
  width: 100%;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    padding: 0;
  }
`

const ImageCarousel = ({ images }) => {
  return (
    <CarouselContainer showThumbs={false}>
      {images.map((image, i) => {
        return (
          <div key={i} style={{ height: "500px", width: "100%" }}>
            <Img
              style={{ width: "100%" }}
              imgStyle={{ objectFit: "contain" }}
              fixed={image.childImageSharp.fixed}
            />
          </div>
        )
      })}
    </CarouselContainer>
  )
}

export default ImageCarousel
